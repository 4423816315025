import React from "react";
import { PageProps, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { Hero, InformationColumns } from "@global";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import ContactForm from "@components/forms/contactForm";
import { Container } from "@util/standard";

interface Props extends PageProps {
  data: Query;
  pageContext: { countryCode: string; slug: string };
}

export default function AboutPage({ data, pageContext }: Props) {
  if (data.allSanityContactPage == null) return null;

  const { seo, hero, formText, contactDetails } = data.allSanityContactPage.nodes[0];

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        <Hero data={hero} />
        {contactDetails && <InformationColumns data={contactDetails} />}
        <Container
          width="70%"
          tabletWidth="80%"
          margin="100px auto"
          tabletMargin="50px auto"
          maxWidth="1000px"
        >
          <ContactForm data={formText} />
        </Container>
      </Layout>
    </>
  );
}

export const query = graphql`
  query allSanityContactPageQuery($slug: String, $countryCode: String) {
    allSanityContactPage(
      filter: { slug: { current: { eq: $slug } }, region: { countryCode: { eq: $countryCode } } }
    ) {
      nodes {
        seo {
          ...sanitySeo
        }
        title
        slug {
          current
        }
        region {
          countryCode
        }
        hero {
          ...sanityHero
        }
        formText {
          ...sanityBlockContent
        }
        contactDetails {
          ...sanityInformationColumns
        }
      }
    }
  }
`;
