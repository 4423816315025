import React from "react";
import JotformEmbed from "react-jotform-embed";
import styled from "styled-components";

import { BlocksContent } from "@global";
import { Maybe, SanityBlockContent } from "@graphql-types";

interface Props {
  data: Maybe<SanityBlockContent> | undefined;
}

const FormWrapper = styled.div`
  width: 100%;
  /* overflow-y: scroll; */
`;

const formEmbedUrl = `https://form.jotform.com/${process.env.GATSBY_CONTACT_FORM_ID}`;

function ContactForm({ data }: Props) {
  return (
    <FormWrapper>
      {data && <BlocksContent data={data} />}
      <JotformEmbed src={formEmbedUrl} />
    </FormWrapper>
  );
}

export default ContactForm;
